import React from 'react';
import styled from 'styled-components';
import { combineClassNames } from '../../utils/base';
import { SeparatorComponent } from '../../types/base';
import generateComponentStyles from '../../utils/styleHelper';

const StyledSeparator = styled.div`
  ${(props) => generateComponentStyles(props.$shape)}
  /* Add specific styling for the separator */
  z-index: 1;
  border: ${(props) => (props.type === 'line' ? '1px solid black' : 'none')};
`;

const Separator: React.FC<SeparatorComponent> = ({
  id, type, title, style,
}) => (
  <StyledSeparator
    id={id}
    type={type}
    $shape={style}
    className={combineClassNames(style.class)}
  >
    {title}
  </StyledSeparator>
);

export default Separator;
