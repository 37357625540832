import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Store } from 'redux';
import { History } from 'history';
import Routes from './routes';
import Theme from './chunks/theme';
import { ApplicationState } from './store';

interface MainProps {
  store: Store<ApplicationState>;
  history: History;
}

const Main: React.FC<MainProps> = (props: MainProps) => {
  const { store, history } = props;

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Theme>
          <Routes />
        </Theme>
      </ConnectedRouter>
    </Provider>
  );
};

export default Main;
