import React from 'react';
import Img404 from './img/img404.svg';

import * as styles from './style.module.scss';

const NotFound: React.FC = () => (
  <div className={styles.notFound}>
    <div className={styles.notFoundCenter}>
      <Img404 />
    </div>
  </div>
);

export default NotFound;
