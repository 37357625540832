.toggleSlider {
  position: relative;
  width: 60px;
  height: 34px;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  cursor: pointer;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

input[type='checkbox'] {
  display: none;
}

input[type='checkbox']:checked + .slider {
  background-color: #6941C6;
}

input[type='checkbox']:checked + .slider:before {
  transform: translateX(26px);
}