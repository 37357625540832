/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from '../../components/Container';
import Checkbox from '../../components/Checkbox';
import Image from '../../components/Image';
import Input from '../../components/Input';
import Radio from '../../components/Radio';
import Select from '../../components/Select';
import Separator from '../../components/Separator';
import Switcher from '../../components/Switcher';
import Text from '../../components/Text';
import { pageSuccess } from '../../store/page/actions';
import merge from 'lodash.merge';
import { analyzeAndPrefetchImages } from '../../utils/prefetchHelper';
import { createWorker } from '../../utils/createWorker';
import { preloadImages } from '../../utils/preloadHelper';
import generateComponentStyles from '../../utils/styleHelper';
import { generateCssRule, filterDisplayProperty, attachScriptsToHeader, attachFontsToHeader, replacePlaceholdersWithVariables } from '../../utils/base';
import { ApplicationState } from '../../store';

const parseOldFormat = (data: any) => {
  const { sections } = data || {};
  const [first] = sections || [];
  return { page: first, settings: {}, variableData: {} };
}

const parseNewFormat = (data: any) => {
  const { screen, variable_data: variableData } = data || {};
  const { sections, settings } = screen || {};
  return { page: sections, settings, variableData };
}

const retryCount = 1;

import { rowSizeGlobal, colCountGlobal } from '../../config/config';

import * as styles from './page.module.scss';
import { NodeTypeScheme } from '../../types/base';

const styleElementId = 'custom-css-header-kdlshHhgjhgf';

const getWebSocketUrl = () => {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const domain = window.location.host;
  return `${protocol}//${domain}/api/1/ws`;
};

const componentMapping = {
  checkbox: Checkbox,
  image: Image,
  input: Input,
  radio: Radio,
  select: Select,
  separator: Separator,
  switcher: Switcher,
  text: Text,
};

const Page: React.FC = () => {
  const dispatch = useDispatch();
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [styleApplied, setStyleApplied] = useState(false);
  const config = useSelector((state: ApplicationState) => state.config);
  const page = useSelector((state: ApplicationState) => state.page);
  const { data } = config || {};
  const { data: pageData2, settings, variableData, sessionData, styleChunks } = page || {};
  const { deviceId, sessionId } = sessionData || {};
  const mergedVariables = merge({}, (settings?.variables || {}), variableData || {});
  const pageData = replacePlaceholdersWithVariables(pageData2, mergedVariables);
  const newSettings = { ...settings, variables: mergedVariables };
  const { page: pp } = pageData || {};

  useEffect(() => {
    const { basic, variables, classes } = data || {};
  
    if (basic && variables && classes) {
      const classKeys = Object.keys(classes);
      let styleElement = document.getElementById(styleElementId);
      if (!styleElement) {
        styleElement = document.createElement('style');
        styleElement.id = styleElementId;
        document.head.appendChild(styleElement);
      }
      const resulting = {};
      classKeys.forEach((k) => {
        const classStyles = filterDisplayProperty(classes[k]);
        resulting[k] = generateComponentStyles(classStyles);
      });
      const cssRules = Object.keys(resulting)
        .map((className) => generateCssRule(className, resulting[className]))
        .join('\n\n');
      styleElement.innerText = `${variables}\n${basic}\n${cssRules}`;
    }
  }, [data]);

  useEffect(() => {
    if (settings?.script) attachScriptsToHeader(settings?.script);
  }, [settings?.script]);

  useEffect(() => {
    if (settings?.fonts) attachFontsToHeader(settings?.fonts);
  }, [settings?.fonts]);

  useEffect(() => {
    if (settings?.page) {
      const { title, icon } = settings.page;
      if (title) {
        document.title = title;
      }
      if (icon) {
        let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = icon;
      }
    }
  }, [settings]);

  const getWebSocketUrl = () => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    return `${protocol}//${window.location.host}/api/1/ws`;
  };
  
  const connectWebSocket = useCallback(() => {
    if (!deviceId) return;

    if (!ws || ws.readyState === WebSocket.CLOSED) {
      try {
        const wsConnection = new WebSocket(getWebSocketUrl());
        
        wsConnection.onopen = () => {
          console.log('WebSocket Connected');
          wsConnection.send(JSON.stringify({ 
            type: 'authorize', 
            groupIdentifier: deviceId,
            sessionIdentifier: sessionId,
          }));
        };
  
        wsConnection.onclose = (event) => {
          console.log('WebSocket Disconnected', event.code, event.reason);
          
          // Implement exponential backoff
          const backoffDelay = Math.min(1000 * Math.pow(2, retryCount), 10000);
          setTimeout(connectWebSocket, backoffDelay);
        };
  
        wsConnection.onerror = (error) => {
          console.error('WebSocket Error:', error);
        };

        wsConnection.onmessage = (event) => {
          const message = JSON.parse(event.data);
          console.log('WebSocket Message:', message);
          if (message.type === 'pageUpdate' && message.data) {
            const { data } = message.data || {};
            const { page: pp, settings, variableData } = data.sections ? parseOldFormat(data) : parseNewFormat(data);
            dispatch(pageSuccess({ data: pp, settings, sessionData, variableData }));
          }
        };
  
        setWs(wsConnection);
      } catch (error) {
        console.error('Failed to create WebSocket:', error);
      }
    }
  }, [ws, deviceId]);

  useEffect(() => {
    connectWebSocket();
    
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [connectWebSocket, deviceId]);

  useEffect(() => {
    if (!pp) return;
    const url = analyzeAndPrefetchImages(pp);
    preloadImages(url);
  }, [pp]);

  return (
    <div className={styles.page}>
      <div className={styles.pageWrap}
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${settings?.cols || colCountGlobal}, 1fr)`,
        gridAutoRows: settings?.rows ? undefined : `${rowSizeGlobal}px`,
        gridTemplateRows: settings?.rows ? `repeat(${settings?.rows}, 1fr)` : undefined,
      }}
      >
        {(typeof pp === 'object' && Object.keys(pp) && !Array.isArray(pp)) ? (
          <Container 
            {...pp as NodeTypeScheme} 
            pageSettings={newSettings}
            styleChunks={styleChunks}
          />
        ) : null}

        {Array.isArray(pp) ? (
          <>
            {pp.map((item) => {
              const Component = componentMapping[item.component];
              if (Component) {
                return <Component key={item.id} {...item} pageSettings={newSettings} styleChunks={styleChunks} />;
              } else if (item.component === 'container' || item.component === 'table') {
                return <Container key={item.id} {...(item as NodeTypeScheme)} pageSettings={newSettings} styleChunks={styleChunks} />;
              }
              return null;
            })}
          </>
        ) : null}
      </div>
    </div>
  );
};
export default Page;
