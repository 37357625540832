import React from 'react';
import Markdown from 'markdown-to-jsx';

const MarkdownContent = ({ text }) => {
  if (!text) {
    return null;
  }
  const stringifiedText = typeof text === 'object' ? JSON.stringify(text) : String(text);

  // const handleLinkClick = (e) => {
  //   e.preventDefault();
  //   const href = e.target.getAttribute('href');
  //   const target = e.target.getAttribute('target');
  //   if (href) {
  //     if (target === '_blank') {
  //       window.open(href, '_blank');
  //     } else {
  //       window.location.href = href;
  //     }
  //   }
  // };

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const href = e.currentTarget.getAttribute('href');
    if (href) {
      window.open(href, '_blank');
    }
  };

  const CustomLink = (props) => (
    <a {...props} onClick={handleLinkClick} />
  );

  const CustomListItem = (props) => {
    const { children } = props;
    const modifiedChildren = React.Children.map(children, (child) => {
      if (React.isValidElement(child) && child.type === 'a') {
        return React.cloneElement(child as React.ReactElement, { onClick: handleLinkClick });
      }
      return child;
    });

    return <li {...props}>{modifiedChildren}</li>;
  };

  return (
    <Markdown
      options={{
        overrides: {
          a: {
            component: CustomLink,
          },
          li: {
            component: CustomListItem,
          },
        },
      }}
    >
      {stringifiedText}
    </Markdown>
  );
};

export default MarkdownContent;