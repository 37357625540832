import React from 'react';

const Theme: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const brand = 'tnbe';
  return (
    <div data-testid="theme" className={`theme-${brand} theme`}>
      {children}
    </div>
  );
};
export default Theme;
