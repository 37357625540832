/* eslint-disable max-len */
import { action } from 'typesafe-actions';
import { StyleUpdateChunk } from '../../types/base';
import { PageActionTypes } from './types';

export const resetFormValues = (data: object) => action(PageActionTypes.RESET_FORM_VALUES, data);
export const resetFormValuesSuccess = (data: object) => action(PageActionTypes.RESET_FORM_VALUES_SUCCESS, data);

export const formValuesRequest = (data: object) => action(PageActionTypes.SET_FORM_VALUES_REQUEST, data);
export const formValuesSuccess = (data: object) => action(PageActionTypes.SET_FORM_VALUES_SUCCESS, data);

export const mergeStyleRequest = (data: StyleUpdateChunk[], indexTrigger?: number) => action(PageActionTypes.MERGE_STYLE_REQUEST, data, indexTrigger);
export const mergeStyleSuccess = (data: StyleUpdateChunk[]) => action(PageActionTypes.MERGE_STYLE_SUCCESS, data);

export const updateNodeActiveState = (data: object) => action(PageActionTypes.UPDATE_NODE_ACTIVE_STATE_REQUEST, data);
export const updateNodeActiveStateSuccess = (data: object) => action(PageActionTypes.UPDATE_NODE_ACTIVE_STATE_SUCCESS, data);

export const pageRequest = (data: object) => action(PageActionTypes.PAGE_REQUEST, data);
export const pageSuccess = (data: object) => action(PageActionTypes.PAGE_SUCCESS, data);
export const pageError = (message: Array<object>) => action(PageActionTypes.PAGE_ERROR, message);

export const clearForms = () => action(PageActionTypes.CLEAR_FORMS);

export const updateItemRequest = (data: object) => action(PageActionTypes.UPDATE_ITEM_REQUEST, data);
export const updateItemSuccess = (data: object) => action(PageActionTypes.UPDATE_ITEM_SUCCESS, data);

export const submitFormRequest = (data: object, triggerNode?: string) => action(PageActionTypes.SUBMIT_FORM_REQUEST, data, triggerNode);
export const submitFormSuccess = (data: object) => action(PageActionTypes.SUBMIT_FORM_SUCCESS, data);

export const uploadFileRequest = (data: object) => action(PageActionTypes.UPLOAD_FILE_REQUEST, data);
