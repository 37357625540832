@import '../../styles/variables.scss';

.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.notFoundCenter {
  max-width: 620px;
  margin: 5px auto 20px auto;

  svg {
    width: 100%;
    height: auto;
  }
}

.notFoundBtn {
  max-width: 620px;
  margin-bottom: 20px;
}
