/* eslint-disable max-len */
import { action } from 'typesafe-actions';
import { ConfigActionTypes } from './types';

export const configRequest = (data: object) => action(ConfigActionTypes.CONFIG_REQUEST, data);

export const emptyConfigRequest = (data: object) => action(ConfigActionTypes.CONFIG_EMPTY_REQUEST, data);

export const configSuccess = (data: object) => action(ConfigActionTypes.CONFIG_SUCCESS, data);

export const configError = () => action(ConfigActionTypes.CONFIG_ERROR);
