interface ParsedJsonResult<T> {
  success: true;
  data: T;
}

interface ParseJsonError {
  success: false;
  error: Error;
}

type ParseJsonResult<T> = ParsedJsonResult<T> | ParseJsonError;

export default function parseJson<T>(jsonString: string): ParseJsonResult<T> {
  try {
    const data = JSON.parse(jsonString) as T;
    return { success: true, data };
  } catch (error) {
    return { success: false, error };
  }
}
