/* eslint-disable @typescript-eslint/default-param-last */
import { Reducer } from 'redux';
import { ConfigState, ConfigActionTypes } from './types';

export const initialState: ConfigState = {
  data: {},
};

const reducer: Reducer<ConfigState> = (state = initialState, action) => {
  switch (action.type) {
    case ConfigActionTypes.CONFIG_SUCCESS: {
      return {
        ...state,
        data: { ...action.payload.data },
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as configReducer };
