import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import { ConfigActionTypes } from './types';
import { configSuccess, configError } from './actions';
import { pageRequest } from '../page/actions';
import apiRequest from '../../utils/apiReq';
import { attachStyle } from '../../utils/base';

function* handleConfigRequest(action: any) {
  const { code, data } = yield call(apiRequest, 'get', '/config');
  if (code === 200) {
    yield put(
      configSuccess({
        data: { ...data },
      }),
    );

    if (data.style) {
      attachStyle(data.style);
    }
    yield put(pageRequest({ location: { ...action.payload } }));
  } else {
    yield put(configError());
  }
}

function* handleConfigEmptyRequest() {
  const { code, data } = yield call(apiRequest, 'get', '/config');
  if (code === 200) {
    if (data.style) {
      attachStyle(data.style);
    }

    yield put(
      configSuccess({
        data: { ...data },
      }),
    );
  } else {
    yield put(configError());
  }
}

function* watchFetchRequest() {
  yield takeEvery(ConfigActionTypes.CONFIG_REQUEST, handleConfigRequest);
  yield takeEvery(ConfigActionTypes.CONFIG_EMPTY_REQUEST, handleConfigEmptyRequest);
}

function* configSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default configSaga;
