// Response object for POST /get
export interface ConfigProps {
  basic?: string;
  variables?: string;
  classes?: JSON;
  urls?: {
    [key: string]: string;
  }
}

export enum ConfigActionTypes {
  CONFIG_REQUEST = '@@config/CONFIG_REQUEST',
  CONFIG_EMPTY_REQUEST = '@@config/CONFIG_EMPTY_REQUEST',
  CONFIG_SUCCESS = '@@config/CONFIG_SUCCESS',
  CONFIG_ERROR = '@@config/CONFIG_ERROR',
}

export interface ConfigState {
  readonly data: ConfigProps;
}
