.selectItemsContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm, 6px);
  overflow: auto;

  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .selectItem {
    display: flex;
    padding: var(--spacing-xxs, 2px) var(--spacing-xs, 4px) var(--spacing-xxs, 2px) 5px;
    justify-content: center;
    align-items: center;
    gap: 3px;

    border-radius: var(--radius-sm, 6px);
    border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
    background: var(--Colors-Background-bg-primary, #FFF);

    span {
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #344054;
    }
  }

  .chipItem {
    color: var(--colors-text-text-secondary-700, #344054);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .chipIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    svg {
      width: 12px;
      height: 12px;

      * {
        stroke: #98A2B3;
      }
    }
  }
}