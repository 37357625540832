import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserHistory } from 'history';
import configureStore from './configureStore';
import Main from './main';

const history = createBrowserHistory();
const store = configureStore(history);

const root = createRoot(document.getElementById('root'));
root.render(<Main store={store} history={history} />);
