import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Page from './routes/page';
import NotFound from './routes/empty';
import FrameComponent from './routes/internalFrame';

const Routes = () => (
  <Switch>
    <Route exact path="/not_found" component={NotFound} />
    <Route exact path="/frame/internal" component={FrameComponent} />
    <Route exact component={Page} />
  </Switch>
);

export default Routes;
