import { v4 as uuidv4 } from 'uuid';

// SessionHelper.ts

export default class SessionHelper {
  private static sessionKey = 'sessionId';
  private static sessionId: string | null = null;

  // Method to get the sessionId from the internal property
  public static getSessionId(): string | null {
    return SessionHelper.sessionId;
  }

  // Method to set the sessionId in the internal property
  public static setSessionId(sessionId: string): void {
    SessionHelper.sessionId = sessionId;
  }

  // Method to generate a new sessionId and store it in the internal property
  public static generateSessionId(): string {
    const sessionId = uuidv4();
    SessionHelper.setSessionId(sessionId);
    return sessionId;
  }

  // Method to clear the sessionId from the internal property
  public static clearSessionId(): void {
    SessionHelper.sessionId = null;
  }

  // Method to get or create a sessionId
  public static getOrCreateSessionId(): string {
    let sessionId = SessionHelper.getSessionId();
    if (!sessionId) {
      sessionId = SessionHelper.generateSessionId();
    }
    return sessionId;
  }
}