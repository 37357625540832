import { call, put, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import request from './req';

interface Response {
  code?: number;
  data?: {
    page?: object;
    grid?: object;
    nextPage?: string;
    close?: boolean;
  };
}
function* open(nextPage: string) {
  if (nextPage.startsWith('http')) {
    window.open(nextPage);
  } else {
    yield put(push(nextPage));
  }
}

function* api(
  method: string,
  path: string,
  data?: object,
  isMultipart?: boolean,
) {
  let response: Response = {};
  response = yield call(request, {
    method, path, data, isMultipart,
  });
  switch (response.code) {
    // page not found
    case 404:
      yield put(push('/not_found'));
      break;
    // redirect
    case 302:
      if (response.data && response.data.nextPage) {
        if (response.data.close) {
          window.close();
          yield delay(500);
          yield call(open, response.data.nextPage);
        } else {
          yield call(open, response.data.nextPage);
        }
      }
      break;
    default:
      break;
  }

  return response;
}

export default api;
