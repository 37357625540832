import React from 'react';
import styled from 'styled-components';
import SVGRenderer from '../../chunks/SVGRenderer';
import { combineClassNames } from '../../utils/base';
import { ImageComponent as Type } from '../../types/base';
import generateComponentStyles from '../../utils/styleHelper';

const StyledImage = styled.img`
 z-index: 1;
  ${(props) => generateComponentStyles(props.$shape)}
`;

const StyledDiv = styled.div`
  ${(props) => generateComponentStyles(props.$shape)}
`;

const Image: React.FC<Type> = ({
  id, image, style, svgString,
}) => {
  // if (image && image.startsWith('$')) {
  //   const iconName = image.substring(1);
  //   const SvgComponent = result[iconName];

  //   if (SvgComponent) {
  //     return <SvgComponent />;
  //   } else {
  //     // Handle the case where the SVG component is not found
  //     console.error(`SVG component "${iconName}" not found in result2.`);
  //     return null;
  //   }
  // }

  // If svgString is provided, render SVGRenderer
  if (svgString) {
    return (
      <SVGRenderer svgString={svgString} />
    );
  }

  // Otherwise, render the StyledImage component
  return (
    <StyledImage
      id={id}
      src={image}
      alt={id}
      $shape={style}
      data-component="image"
      className={combineClassNames(style.class)}
    />
  );
};

export default Image;
