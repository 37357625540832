// utils/prefetchHelper.ts
const fetchedImageUrls: Set<string> = new Set();

export const analyzeAndPrefetchImages = (pp: any) => {
  const imageUrls: Set<string> = new Set();

  const traverseScheme = (node: any) => {
    if (Array.isArray(node)) {
      node.forEach(traverseScheme);
    } else if (typeof node === 'object' && node !== null) {
      // Check for image URLs in the background property
      if (node.style) {
        extractImageUrls(node.style);
      }

      // Traverse child nodes
      Object.values(node).forEach(traverseScheme);
    }
  };

  const extractImageUrls = (style: any) => {
    if (style.background) {
      style.background.forEach((bg: any) => {
        if (bg.type === 'image' && bg.value) {
          imageUrls.add(bg.value);
        }
      });
    }

    // Check nested properties (states, events, breakpoints)
    if (style.states) {
      Object.values(style.states).forEach(extractImageUrls);
    }
    if (style.events) {
      Object.values(style.events).forEach((event: any) => {
        if (Array.isArray(event)) {
          event.forEach((e) => {
            if (e.style) {
              extractImageUrls(e.style);
            }
          });
        }
      });
    }
    if (style.breakpoints) {
      Object.values(style.breakpoints).forEach(extractImageUrls);
    }
  };

  traverseScheme(pp);

  // Filter out already fetched URLs
  const newImageUrls = Array.from(imageUrls).filter(url => !fetchedImageUrls.has(url));

  // Add new URLs to the fetched set
  newImageUrls.forEach(url => fetchedImageUrls.add(url));

  // Send unique new image URLs to the Web Worker
  // worker.postMessage(newImageUrls);

  return newImageUrls;
};