export type RequestOptions = {
  method: string;
  path: string;
  data?: Record<string, any>;
  isMultipart?: boolean;
};

const BASE_API_PATH = '/api/1';
const BASE_SVC_PATH = '/svc/1';

export default async function request({
  method,
  path,
  data,
  isMultipart,
}: RequestOptions) {
  const url = isMultipart ? `${BASE_SVC_PATH}${path}` : `${BASE_API_PATH}${path}`;

  let body: string | FormData = JSON.stringify(data);
  const headers: Record<string, string> = {
    Accept: 'application/json',
  };

  if (data && isMultipart) {
    const formData = new FormData();
    Object.entries(data).forEach(([name, value]) => formData.append(name, value));
    body = formData;
    delete headers['Content-Type'];
  } else {
    headers['Content-Type'] = 'application/json; charset=utf-8';
  }

  const response = await fetch(url, {
    method,
    headers,
    body,
  });

  if (response.status !== 200) {
    let message = await response.text();
    if (message.startsWith('{')) {
      message = JSON.parse(message).data;
    }
    throw new Error(`${response.status} ${response.statusText}. ${message}`);
  }

  return response.json();
}
